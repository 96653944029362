<template>
  <SubletPage>
    <CollapseCard
      is-primary
      initially-open
      class="mb-4"
      :closed-identifier="dynamicClosedIdentifier"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <div class="info-text-container">
          <p v-html="dynamicInfoTitle"></p>
          <p v-html="dynamicInfoText"></p>
          <p v-html="dynamicHousingTypeInfoText"></p>
          <div v-if="reasonType === 'studie'">
            <p>Dokumenten du behöver ladda upp är:</p>
            <ul>
              <li>Andrahandshyresavtal</li>
              <li>Intyg från CSN <i>(alternativt antagningsbevis)</i></li>
              <li>Registreringsintyg</li>
              <li v-if="reason.movingAbroad">
                Vid studier utomlands behövs dokumentation som bevisar din
                utresa ur Sverige
              </li>
            </ul>
          </div>
          <div class="q-and-a-container">
            <h3>Frågor och svar</h3>
            <div>
              <h3>Hur ska ett andrahandshyresavtal se ut?</h3>
              <p>
                Du kan antingen skriva avtalet mellan dig och din
                andrahandshyresgäst själv eller så kan du utgå från en mall. Vi
                rekommenderar att du använder Fastighetsägarnas mall för
                <a
                  href="https://app.fastdok.se/external/contract-create/174"
                  target="_blank"
                  >andrahandsuthyrning</a
                >. Tänk på att du endast får göra tillägg på din hyra om den
                hyrs ut möblerad och att eventuella tillägg ska specificeras.
              </p>
            </div>
            <div v-for="item in dynamicReasonTypeFAQ" :key="item.id">
              <h3>{{ item.question }}</h3>
              <p v-html="item.answer"></p>
            </div>
            <div v-for="item in dynamicHousingTypeFAQ" :key="item.id">
              <h3>{{ item.question }}</h3>
              <p>{{ item.answer }}</p>
            </div>
          </div>
        </div>
        <p>Tänk på att:</p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          <strong>Tips:</strong> För att fota dokument på bästa sätt
          rekommenderar vi att du använder dig av en app som scannar av
          dokument. Exempel på appar som fungerar bra till både iOS och Android
          är Adobe Scan och Genius Scan, båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a
            :href="`mailto:${getSupportAddress}?subject=Andra%20Hand`"
            target="_blank"
          >
            {{ getSupportAddress }}</a
          >
        </p>
      </template>
    </CollapseCard>
    <Summary
      v-if="!applicationIsEditable && !documentsRejected"
      category="DOCUMENTS"
      class="documents-view-wrapper"
    >
      <SummaryBody sub-heading="Upladdade filer" class="documents-subheading">
        <SubletDocumentsGrid
          v-if="documentsForSelectedReason.length > 0"
          :documents="documentsForSelectedReason"
          :application-id="application.id"
        />
      </SummaryBody>
    </Summary>
    <div v-else class="upload-area">
      <p>* <i>Obligatoriskt att ladda upp</i></p>
      <SubletDocumentsUpload
        v-if="reasonType"
        label="Här laddar du upp ert andrahandshyresavtal*"
        document-category="andrahandshyresavtal"
        :documents="getFilteredDocuments('andrahandshyresavtal')"
      />
      <template v-if="reasonType === 'sambo'">
        <SubletDocumentsUpload
          label="Här laddar du upp ditt familjebevis*"
          document-category="familjebevis"
          :documents="getFilteredDocuments('familjebevis')"
        />
        <SubletDocumentsUpload
          v-if="housingType === 'hyresratt'"
          label="Här laddar du upp ditt nuvarande hyresavtal*"
          document-category="kontrakt"
          :documents="getFilteredDocuments('kontrakt')"
        />
        <SubletDocumentsUpload
          v-if="housingType === 'bostadsratt'"
          label="Här laddar du upp medlemsregister för BRF*"
          document-category="medlemsregister"
          :documents="getFilteredDocuments('medlemsregister')"
        />
        <SubletDocumentsUpload
          v-if="housingType === 'villa'"
          label="Här laddar du upp köpekontrakt*"
          description="Här laddar du upp köpekontrakt där det framgår vem som äger villan
              dit ni ska flytta."
          document-category="köpekontrakt"
          :documents="getFilteredDocuments('köpekontrakt')"
        />
      </template>
      <template v-if="reasonType === 'arbete'">
        <SubletDocumentsUpload
          label="Här laddar du upp anställningsbevis*"
          document-category="anställningsbevis"
          :documents="getFilteredDocuments('anställningsbevis')"
        />
        <SubletDocumentsUpload
          v-if="reason.movingAbroad"
          label="Här laddar du upp bevis på din utresa ur Sverige*"
          document-category="resehandling"
          :documents="getFilteredDocuments('resehandling')"
        />
        <SubletDocumentsUpload
          v-if="reason.movingAbroad"
          label="Här laddar du upp fullmakt*"
          document-category="fullmakt"
          :documents="getFilteredDocuments('fullmakt')"
        />
      </template>
      <template v-if="reasonType === 'studie'">
        <SubletDocumentsUpload
          label="Här laddar du upp intyg från CSN*"
          description="Kommer du inte att ansöka om CSN? Då kan du istället ladda upp ditt antagningsbevis."
          document-category="csn-intyg"
          :documents="getFilteredDocuments('csn-intyg')"
        />
        <SubletDocumentsUpload
          label="Här laddar du upp registreringsintyg"
          document-category="registreringsintyg"
          :documents="getFilteredDocuments('registreringsintyg')"
        />
        <SubletDocumentsUpload
          v-if="reason.movingAbroad"
          label="Här laddar du upp bevis på din utresa ur Sverige*"
          document-category="resehandling"
          :documents="getFilteredDocuments('resehandling')"
        />
        <SubletDocumentsUpload
          v-if="reason.movingAbroad"
          label="Här laddar du upp fullmakt*"
          document-category="fullmakt"
          :documents="getFilteredDocuments('fullmakt')"
        />
        <SubletDocumentsUpload
          label="Här laddar du upp övriga dokument som styrker din anledning"
          document-category="övrigt"
          :documents="getFilteredDocuments('övrigt')"
        />
      </template>
      <template v-if="reasonType === 'other'">
        <SubletDocumentsUpload
          label="Här laddar du upp dokument som styrker din anledning*"
          document-category="other"
          :documents="getFilteredDocuments('other')"
        />
      </template>
    </div>
    <BaseButton
      class="go-to-next-category-btn"
      type="button"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-disabled="!applicationIsEditable"
      @click="goToNextCategory"
      >Gå vidare
      <BaseIconText
        class="info-modal-arrow-right"
        :icon-url="icons.ICONS.ARROW_RIGHT"
      ></BaseIconText
    ></BaseButton>
  </SubletPage>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SubletPage from '@/components/SubletPage.vue';
import SubletDocumentsUpload from '@/components/SubletDocumentsUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import icons from '@/utils/icon-constants';
import svgs from '@/utils/icon-constants';
import SubletDocumentsGrid from '@/components/SubletDocumentsGrid.vue';

export default {
  name: 'Documents',

  components: {
    SubletPage,
    SubletDocumentsUpload,
    CollapseCard,
    BaseButton,
    BaseIconText,
    Summary,
    SummaryBody,
    SubletDocumentsGrid
  },
  data() {
    return {
      icons,
      svgs
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    ...mapGetters({
      getSupportAddress: 'app/getSupportAddress',
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      rejectedCategories: 'subletApplication/rejectedCategories'
    }),
    documentsRejected() {
      return (
        this.rejectedCategories.includes('documents') ||
        this.rejectedCategories.includes('computedDocumentsGroups')
      );
    },
    categories() {
      const categories = Object.keys(this.computedDocumentsGroups).map(key => ({
        rejectReason: this.computedDocumentsGroups[key].rejectReason,
        status: this.computedDocumentsGroups[key].status,
        category: key
      }));
      return [...this.documents, ...categories];
    },
    computedDocumentsGroups() {
      return this.application?.computedDocumentsGroups || {};
    },
    documents() {
      return this.application?.documents || [];
    },
    reason() {
      return this.application?.reason;
    },
    reasonType() {
      return this.application?.reason?.type;
    },
    housingType() {
      return this.application?.reason?.housingType;
    },
    dynamicClosedIdentifier() {
      switch (this.reasonType) {
        case 'sambo':
          return 'cohabitant_card_closed';
        case 'arbete':
          return 'work_info_card_closed';
        case 'studie':
          return 'study_info_card_closed';
        default:
          return 'default_info_card_closed';
      }
    },
    dynamicInfoTitle() {
      switch (this.reasonType) {
        case 'sambo':
          return 'Här laddar du upp dokument som krävs för provsambo.';
        case 'arbete':
          return 'Här laddar du upp dokument som krävs för arbete på annan ort eller utomlands.';
        case 'studie':
          return 'Här laddar du upp dokument som krävs för studie på annan ort eller utomlands.';
        default:
          return 'Här laddar du upp dokument.';
      }
    },
    dynamicInfoText() {
      const warrantTemplateLink = `
          <a
              style="text-decoration: underline;"
              onmouseover="this.style.color='#409fff';"
              onmouseout="this.style.color='#878b9d';"
              href="https://bytesansokan-pdfs-se-prod.s3.eu-north-1.amazonaws.com/public/Fullmakt%20utlandsvistelse%20andrahandsuthyrning.pdf "
              target="_blank"
          >från vår mall.</a>`;

      switch (this.reasonType) {
        case 'sambo':
          return 'För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden familjebevis för dig som ska hyra ut.';
        case 'arbete':
          if (this.reason && this.reason.movingAbroad) {
            return (
              'För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du tillhandahåller anställningsbevis. Vid arbete utomlands behövs även dokumentation som bevisar din utresa ur Sverige, samt en fullmakt som gör att en annan person kan agera i ditt intresse (OBS! Detta får ej vara andrahandshyresgästen). För att skapa en fullmakt kan ni utgå ' +
              warrantTemplateLink
            );
          } else {
            return 'För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du tillhandahåller anställningsbevis.';
          }
        case 'studie':
          if (this.reason && this.reason.movingAbroad) {
            return (
              'För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du tillhandahåller en del olika dokument. Vid studier utomlands behövs även dokumentation som bevisar din utresa ur Sverige, samt en fullmakt som gör att en annan person kan agera i ditt intresse (OBS! Detta får ej vara andrahandshyresgästen). För att skapa en fullmakt kan ni utgå ' +
              warrantTemplateLink
            );
          } else {
            return 'För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden att du tillhandahåller en del olika dokument.';
          }
        default:
          return 'För att säkerställa att rätt förutsättningar finns för att kunna hyra ut din bostad kräver hyresvärden dokument från dig som ska hyra ut. Dessa dokument ska styrka den andledning du angett i steget innan.';
      }
    },
    dynamicHousingTypeInfoText() {
      if (this.reasonType === 'sambo') {
        switch (this.housingType) {
          case 'hyresratt':
            return 'Du behöver även ladda upp dokument där det framgår vem som står på kontraktet.';
          case 'bostadsratt':
            return 'Du behöver även ladda upp medlemsregister för BRF.';
          case 'villa':
            return 'Du behöver även ladda upp köpekontrakt där det framgår vem som äger villan dit ni ska flytta.';
          default:
            return '';
        }
      }
      return null;
    },
    dynamicReasonTypeFAQ() {
      const familyCertLink = `Beställning av familjebevis gör du enklast
          <a
              style="text-decoration: underline;"
              onmouseover="this.style.color='#409fff';"
              onmouseout="this.style.color='#878b9d';"
              href="https://skatteverket.se/privat/folkbokforing/bestallpersonbevis/allapersonbevis.4.361dc8c15312eff6fdb20.html?mode=list&code=1209"
              target="_blank"
          >här</a>
          (länk till Skatteverket).`;

      switch (this.reasonType) {
        case 'sambo':
          return [
            {
              question:
                'Vad är ett familjebevis och varför måste jag ladda upp ett sådant?',
              answer:
                'Ett familjebevis är en viss typ av personbevis som inte bara visar folkbokföringsadress utan även eventuella barn och andra familjeförhållanden. Familjebeviset skickas till din folkbokföringsadress via post och går ej att hämta online. Körkort, ID-kort samt pass gäller ej som personbevis. ' +
                familyCertLink,

              id: 0
            },
            {
              question: 'För vem ska jag ladda upp familjebevis?',
              answer:
                'Det räcker med att ladda upp familjebevis för dig som ska hyra ut, det behövs alltså inte för din sambo. Tänk på att familjebeviset får ej vara äldre än 3 månader.',
              id: 1
            }
          ];
        case 'arbete': {
          let workArray = [
            {
              question: 'Varför måste jag ladda upp ett anställningsbevis?',
              answer: 'För att säkerställa att skälet du angett är giltigt.',
              id: 2
            }
          ];

          if (this.reason.movingAbroad) {
            workArray.push({
              question:
                'Vad ska jag ladda upp för att styrka min utresa ur Sverige?',
              answer:
                'Det kan vara resehandlingar som flygbiljett eller visum för landet dit du kommer att flytta för att genomföra ditt arbete.',
              id: 3
            });
          }

          return workArray;
        }

        case 'studie': {
          let studyArray = [
            {
              question: 'Varför måste jag ladda upp ett intyg från CSN?',
              answer: 'För att säkerställa att skälet du valt är giltigt.',
              id: 4
            },
            {
              question: 'Jag kommer inte att ansöka om CSN, vad gör jag då?',
              answer:
                'Om du väljer att inte ansöka om CSN (Centrala Studiestödsnämnden) för finansiellt stöd under dina studier, måste du istället tillhandahålla andra dokument som antagningsbevis för att styrka din antagning till den skola eller institut där du planerar att genomföra dina studier. ',
              id: 5
            },
            {
              question: 'Varför måste jag ladda upp ett registreringsintyg?',
              answer:
                'För att säkerställa att du faktiskt kommer att genomföra dina studier.',
              id: 6
            },
            {
              question:
                'När måste jag senast ha laddat upp mitt registreringintyg?',
              answer:
                'Du ska ladda upp ditt registreringintyg så fort du registrerat dig på den skola / institut där du kommer att genomföra dina studier. Du kan fortfarande skicka in din ansökan utan att ha laddat upp ett registreringintyg men kan komma att behöva komplettera med detta i efterhand.',
              id: 7
            }
          ];

          if (this.reason.movingAbroad) {
            studyArray.push({
              question:
                'Vad ska jag ladda upp för att styrka min utresa ur Sverige?',
              answer:
                'Det kan vara resehandlingar som flygbiljett eller visum för landet dit du kommer att flytta för att genomföra dina studier.',
              id: 8
            });
          }

          return studyArray;
        }
        default:
          return [
            {
              question: 'Vad ska jag ladda upp för dokument?',
              answer:
                'Dokumenten bör tydligt styrka den angivna orsaken. <br/> <strong>Ex.</strong> Du har gett anledningen för att hyra ut din lägenhet som <i>"Flytta till landställe."</i> <br/> - då laddar du upp ägandebevis eller köpekontrakt för ditt landställe.',
              id: 9
            }
          ];
      }
    },
    dynamicHousingTypeFAQ() {
      switch (this.housingType) {
        case 'hyresratt':
          return [
            {
              question:
                'Varför måste jag ladda upp dokument med information om vem som står på kontraktet?',
              answer:
                'Hyresvärden behöver kunna säkerställa vem det är som har förstahandskontraktet till boendet som ni kommer att provbo i.',
              id: 10
            }
          ];
        case 'bostadsratt':
          return [
            {
              question:
                'Varför måste jag ladda upp medlemsregister för bostadsrättsföreningen dit vi ska flytta?',
              answer:
                'Hyresvärden behöver kunna säkerställa vem det är som äger boendet som ni kommer att provbo i.',
              id: 11
            }
          ];
        case 'villa':
          return [
            {
              question:
                'Varför måste jag ladda upp köpekontrakt från ägaren av villan dit vi ska flytta?',
              answer:
                'Hyresvärden behöver kunna säkerställa vem det är som äger boendet som ni kommer att provbo i.',
              id: 12
            }
          ];
        default:
          return '';
      }
    },
    getDocumentsPerCategory() {
      if (!this.reason || !this.reason.type) {
        return '';
      }

      switch (this.reason.type) {
        case 'sambo':
          switch (this.reason.housingType) {
            case 'hyresratt':
              return ['andrahandshyresavtal', 'familjebevis', 'kontrakt'];
            case 'bostadsratt':
              return [
                'andrahandshyresavtal',
                'familjebevis',
                'medlemsregister'
              ];
            default:
              return ['andrahandshyresavtal', 'familjebevis', 'köpekontrakt'];
          }
        case 'arbete':
          return [
            'andrahandshyresavtal',
            'anställningsbevis',
            'resehandling',
            'fullmakt'
          ];
        case 'studie':
          return [
            'andrahandshyresavtal',
            'csn-intyg',
            'registreringsintyg',
            'resehandling',
            'fullmakt',
            'övrigt'
          ];
        case 'other':
          return ['andrahandshyresavtal', 'other'];
        default:
          return [];
      }
    },
    documentsForSelectedReason() {
      const documentsToShow = this.getDocumentsPerCategory;
      const filteredDocuments = this.documents.filter(doc =>
        documentsToShow.includes(doc.category)
      );

      return filteredDocuments;
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'app/goToNextCategory'
    }),
    getFilteredDocuments(category) {
      if (!category || this.documents.length === 0) {
        return [];
      }

      const currDocumentCat = this.documents.filter(
        document => document.category === category
      );

      return currDocumentCat;
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  &:hover {
    color: var(--color-blue);
  }
}
.info-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .q-and-a-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }
}

.upload-area > * {
  margin-bottom: 3.2rem;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.go-to-next-category-btn {
  margin-top: 4.8rem;
  :deep(.inner-wrapper) {
    > span {
      display: flex;
      gap: 0.8rem;
      .icon {
        margin: 0;
      }
    }
  }
}

.documents-subheading {
  @media ($mobile) {
    display: flex;
    align-items: center !important;
    width: 100%;
  }
}
</style>
