<template>
  <SubletPage v-if="currentTenants" :categories="currentTenants">
    <SubletForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      v-on="$listeners"
    >
      <SubletInput
        v-model="currentTenants[0].name"
        label="Namn"
        placeholder="Namn"
        description="Ange för- och efternamn på dig som hyr ut"
        autocomplete="given-name"
      />
      <Fieldset>
        <SubletInput
          v-model="currentTenants[0].email"
          label="E-post"
          placeholder="E-post"
          description="Ange din e-postadress"
          type="email"
          :validation="validEmail"
          error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
          autocomplete="email"
        />
        <SubletInput
          v-model="currentTenants[0].phone"
          label="Telefonnummer"
          placeholder="Telefonnummer"
          description="Ange ditt telefonnummer"
          error-text="Fältet får endast innehålla siffror"
          :validation="validateNumber"
          inputmode="numeric"
          pattern="[0-9]*"
          autocomplete="tel"
        />
      </Fieldset>
      <SubletInput
        v-model="currentTenants[0].ssn"
        label="Personnummer"
        placeholder="Personnummer"
        description="Ange ditt personnummer"
        :error-text="ssnErrorText"
        :validation="validateSsn"
      />
      <ExpandableForm
        :disabled="!applicationIsEditable"
        :btn-text="formExpanded ? 'Ta bort medboende' : 'Lägg till medboende'"
        :form-expanded="formExpanded"
        :is-personal-info="true"
        @click="handleButtonClick"
      >
        <template v-if="currentTenants[1]">
          <div class="top-container">
            <h1 ref="expandableForm">Medboende</h1>
            <InfoButtonModal
              v-if="formExpanded"
              class="co-tenant-info-modal"
              title="Vad är detta?"
              ><p class="text-info">
                Detta gäller inte inneboende, medboende är en person som står
                med på kontraktet.
              </p></InfoButtonModal
            >
          </div>
          <SubletInput
            v-model="currentTenants[1].name"
            label="Namn"
            placeholder="Namn"
            description="Angeför- och efternamn på medboende"
            autocomplete="given-name"
          />
          <Fieldset>
            <SubletInput
              v-model="currentTenants[1].email"
              label="E-post"
              placeholder="E-post"
              description="Ange e-postadress till medboende"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              autocomplete="email"
            />
            <SubletInput
              v-model="currentTenants[1].phone"
              label="Telefonnummer"
              placeholder="Telefonnummer"
              description="Ange telefonnummer till medboende"
              error-text="Fältet får endast innehålla siffror"
              :validation="validateNumber"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="tel"
            />
          </Fieldset>
          <SubletInput
            v-model="currentTenants[1].ssn"
            label="Personnummer"
            placeholder="Personnummer"
            description="Ange personnummer för medboende"
            :error-text="ssnErrorText"
            :validation="validateSsn"
          />
          <InfoModal
            v-if="confirmModalVisible"
            title="Är du säker?"
            :mobile-full-width="isMobile"
            :show-close-btn="false"
            confirm-label="Ta bort"
            :confirm-icon="false"
            abort-label="Avbryt"
            @click="confirmModalClick"
          >
            <p>Vill du ta bort medboenden?</p></InfoModal
          >
        </template>
      </ExpandableForm>
    </SubletForm>
  </SubletPage>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePersonalNumber
} from '@/utils/input-validations';
import Fieldset from '@/components/Fieldset.vue';
import SubletInput from '@/components/SubletInput.vue';
import SubletForm from '@/components/SubletForm.vue';
import SubletPage from '@/components/SubletPage.vue';
import ExpandableForm from '@/components/ExpandableForm.vue';
import InfoModal from '@/components/InfoModal.vue';
import InfoButtonModal from '@/components/InfoButtonModal.vue';
import { scrollExpandableForm } from '@/utils/scroll';
import { cloneTenant } from '@/utils/sublet';

export default {
  name: 'CurrentTenants',
  components: {
    Fieldset,
    SubletInput,
    SubletForm,
    SubletPage,
    ExpandableForm,
    InfoModal,
    InfoButtonModal
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      formExpanded: false,
      validEmail,
      validateNumber,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      confirmModalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable'
    }),
    currentTenants: {
      get() {
        return this.application?.currentTenants;
      },
      set() {
        this.$emit();
      }
    }
  },
  mounted() {
    if (this.currentTenants && this.currentTenants[1]) {
      this.formExpanded = true;
    }
  },
  methods: {
    async confirmModalClick(eventType) {
      if (eventType === 'CONFIRM') {
        //this.currentTenants.pop();
        this.$delete(this.currentTenants, 1);
        this.emitEditing();
        this.formExpanded = false;
      }
      this.confirmModalVisible = false;
    },
    handleButtonClick() {
      const expand = !this.formExpanded;

      if (!expand && this.currentTenants[1]) {
        this.confirmModalVisible = true;
      } else {
        // Create second tenant if not exist
        this.$set(this.currentTenants, 1, cloneTenant(this.currentTenants));
        this.emitEditing();
        this.formExpanded = expand;
      }

      this.$nextTick(() => {
        this.scroll();
      });
    },
    scroll() {
      const formInput = this.$refs.expandableForm;
      const targetId = 'sticky-text-container';

      scrollExpandableForm(formInput, targetId);
    },
    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    emitEditing() {
      this.$emit('isEditing', true);
    }
  }
};
</script>
<style lang="scss" scoped>
.top-container {
  display: flex;
  align-items: center;

  .co-tenant-info-modal {
    margin-bottom: 0.3rem;
  }

  .text-info {
    padding: 1rem 0;
  }
}
</style>
