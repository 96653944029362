<template>
  <SubletPage :names="application?.currentTenants?.name">
    <div class="data-summary-wrapper">
      <Summary
        title="Uppgifter"
        category="PERSONAL_INFORMATION"
        :has-empty-info-fields="
          applicationCategoriesWithErrors.PERSONAL_INFORMATION
        "
        :reject-reason="rejectedCategory(currentTenants)"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Dina uppgifter">
          <div
            v-if="
              application &&
              application.currentTenants &&
              application.currentTenants[0] &&
              Object.keys(application.currentTenants[0]).some(
                key => !!application.currentTenants[0][key]
              )
            "
          >
            <SummaryField
              v-if="
                application &&
                application.currentTenants &&
                application.currentTenants[0]
              "
              label="Namn:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(currentTenants[0], 'name')"
              >{{ currentTenants[0].name }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.currentTenants &&
                application.currentTenants[0]
              "
              label="Personnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(currentTenants[0], 'ssn')"
              >{{ currentTenants[0].ssn }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.currentTenants &&
                application.currentTenants[0]
              "
              label="E-post:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(currentTenants[0], 'email')
              "
              >{{ currentTenants[0].email }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.currentTenants &&
                application.currentTenants[0]
              "
              label="Telefonnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(currentTenants[0], 'phone')
              "
              >{{ currentTenants[0].phone }}</SummaryField
            >
          </div>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
        <SummaryBody
          v-if="application && application.currentTenants && currentTenants[1]"
          sub-heading="Medhyresgäst"
        >
          <SummaryField
            v-if="
              application &&
              application.currentTenants &&
              application.currentTenants[1]
            "
            label="Namn:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="isFieldMissing(currentTenants[1], 'name')"
            >{{ currentTenants[1].name }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.currentTenants &&
              application.currentTenants[1]
            "
            label="Personnummer:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="isFieldMissing(currentTenants[1], 'ssn')"
            >{{ currentTenants[1].ssn }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.currentTenants &&
              application.currentTenants[1]
            "
            label="E-post:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="isFieldMissing(currentTenants[1], 'email')"
            >{{ currentTenants[1].email }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.currentTenants &&
              application.currentTenants[1]
            "
            label="Telefonnummer:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="isFieldMissing(currentTenants[1], 'phone')"
            >{{ currentTenants[1].phone }}</SummaryField
          >
        </SummaryBody>
      </Summary>
      <Summary
        title="Lägenhet"
        category="APARTMENT"
        :has-empty-info-fields="applicationCategoriesWithErrors.APARTMENT"
        :reject-reason="rejectedCategory([apartment])"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Din lägenhet">
          <div
            v-if="
              application &&
              application.apartment &&
              Object.keys(application.apartment).some(
                key => !!application.apartment[key]
              )
            "
          >
            <SummaryField
              v-if="application && application.apartment"
              label="Adress:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(apartment, 'address')"
              >{{ apartment.address }}</SummaryField
            >
            <SummaryField
              v-if="application && application.apartment"
              label="Postnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(apartment, 'postalCode')"
              >{{ apartment.postalCode }}</SummaryField
            >
            <SummaryField
              v-if="application && application.apartment"
              label="Ort:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(apartment, 'city')"
              >{{ apartment.city }}</SummaryField
            >
            <SummaryField
              v-if="application && application.apartment"
              label="Objekt/kontraktnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(apartment, 'objectNumber')"
              >{{ apartment.objectNumber }}</SummaryField
            >
            <SummaryField
              v-if="application && application.apartment"
              label="Lägenhetsnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(apartment, 'apartmentNumber')
              "
              >{{ apartment.apartmentNumber }}</SummaryField
            >
            <SummaryField
              v-if="application && application.apartment"
              label="Ange din månadshyra:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(apartment, 'rent')"
              >{{ apartment.rent }}</SummaryField
            >
            <SummaryField
              v-if="application && application.apartment"
              label="Hyra ut möblerat:"
              >{{ calcSubletFurnished }}</SummaryField
            >
          </div>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
      </Summary>
      <Summary
        title="Skäl"
        category="REASON"
        :has-empty-info-fields="applicationCategoriesWithErrors.REASON"
        :reject-reason="rejectedCategory([reason])"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Ditt skäl">
          <div v-if="application.reason && application.reason.type">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Valt skäl:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'type')"
              >{{ renderReasonTypeData }}</SummaryField
            >
          </div>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
        <SummaryBody sub-heading="Datum för uthyrning">
          <div v-if="application.reason && application.reason.type">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Önskar hyra ut fr.o.m:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'rentFrom')"
              >{{
                isValidDate(reason.rentFrom)
                  ? new Date(reason.rentFrom).toLocaleDateString('sv-SE')
                  : ''
              }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Önskar hyra ut t.o.m:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'rentTo')"
            >
              {{
                isValidDate(reason.rentTo)
                  ? new Date(reason.rentTo).toLocaleDateString('sv-SE')
                  : ''
              }}</SummaryField
            >
          </div>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
        <div
          v-if="
            application &&
            application.reason &&
            application.reason.type &&
            reason.type === 'sambo'
          "
          class="more-info-wrapper"
        >
          <SummaryBody sub-heading="Uppgifter på den du ska provbo med">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Namn:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'partnerName')"
              >{{ reason.partnerName }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Personnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'partnerSsn')"
              >{{ reason.partnerSsn }}</SummaryField
            >
          </SummaryBody>
          <SummaryBody sub-heading="Adress under uthyrningstiden">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Adress:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'address')"
              >{{ reason.address }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Postnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'postalCode')"
              >{{ reason.postalCode }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Ort:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'city')"
              >{{ reason.city }}</SummaryField
            >
          </SummaryBody>
          <SummaryBody sub-heading="Boendeform">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Boendeform:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'housingType')"
              >{{ renderHousingTypeData }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.reason &&
                application.reason.type &&
                reason.housingType === 'hyresratt'
              "
              label="Namn på hyreskontrakt:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                reason.housingType === 'hyresratt'
                  ? isFieldMissing(reason, 'nameOnContract')
                  : null
              "
              >{{ reason.nameOnContract }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              :label="labelName"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'houseOwner')"
              >{{ reason.houseOwner }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              :label="labelPhone"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'ownerPhone')"
              >{{ reason.ownerPhone }}</SummaryField
            >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              :label="labelEmail"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'ownerEmail')"
              >{{ reason.ownerEmail }}</SummaryField
            >
            <div v-if="secondHouseOwner">
              <SummaryField
                v-if="
                  application && application.reason && application.reason.type
                "
                :label="labelName"
                :info-html="'Information saknas'"
                :has-empty-info-fields="
                  isFieldMissing(reason, 'secondHouseOwner')
                "
                >{{ reason.secondHouseOwner }}</SummaryField
              >
              <SummaryField
                v-if="
                  application && application.reason && application.reason.type
                "
                :label="labelPhone"
                :info-html="'Information saknas'"
                :has-empty-info-fields="
                  isFieldMissing(reason, 'secondOwnerPhone')
                "
                >{{ reason.secondOwnerPhone }}</SummaryField
              >
              <SummaryField
                v-if="
                  application && application.reason && application.reason.type
                "
                :label="labelEmail"
                :info-html="'Information saknas'"
                :has-empty-info-fields="
                  isFieldMissing(reason, 'secondOwnerEmail')
                "
                >{{ reason.secondOwnerEmail }}</SummaryField
              >
            </div>
          </SummaryBody>
          <SummaryBody
            v-if="application.reason.housingType !== 'hyresratt'"
            :sub-heading="subHeadingOwner"
          >
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              :label="labelOwner"
              >{{ calcPropertyOwner }}</SummaryField
            >
          </SummaryBody>
        </div>
        <div
          v-if="
            (application &&
              application.reason &&
              application.reason.type &&
              reason.type === 'arbete') ||
            (application &&
              application.reason &&
              application.reason.type &&
              reason.type === 'studie')
          "
          class="more-info-wrapper"
        >
          <SummaryBody sub-heading="Flytt utomlands">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Jag kommer att bo utomlands:"
              >{{ calcMovingAbroad }}</SummaryField
            >
          </SummaryBody>
          <SummaryBody sub-heading="Ort">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Ort under uthyrningstiden:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'city')"
              >{{ reason.city }}</SummaryField
            >
          </SummaryBody>
        </div>
        <div
          v-if="
            application &&
            application.reason &&
            application.reason.type &&
            reason.type === 'other'
          "
          class="more-info-wrapper"
        >
          <SummaryBody sub-heading="Anledning">
            <SummaryField
              v-if="
                application && application.reason && application.reason.type
              "
              label="Beskriv annan anledning:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="isFieldMissing(reason, 'text')"
              >{{ reason.text }}</SummaryField
            >
          </SummaryBody>
        </div>
      </Summary>
      <Summary
        title="Dokument"
        category="DOCUMENTS"
        class="documents-view-wrapper"
        :has-empty-info-fields="applicationCategoriesWithErrors.DOCUMENTS"
        :reject-reason="rejectedDocumentCategoryReason"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Upladdade filer">
          <SubletDocumentsGrid
            v-if="documentsForSelectedReason.length > 0"
            :documents="documentsForSelectedReason"
            :application-id="application.id"
          />
          <p v-else>Inga uppladdade filer</p>
        </SummaryBody>
      </Summary>
      <Summary
        title="Andrahandshyresgäst"
        category="SUBTENANT_INFORMATION"
        :has-empty-info-fields="
          applicationCategoriesWithErrors.SUBTENANT_INFORMATION
        "
        :reject-reason="rejectedCategory(secondaryTenants)"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Din andrahandshyresgäst">
          <div
            v-if="
              application &&
              application.secondaryTenants &&
              application.secondaryTenants[0] &&
              Object.keys(application.secondaryTenants[0]).some(
                key => !!application.secondaryTenants[0][key]
              )
            "
          >
            <SummaryField
              v-if="
                application &&
                application.secondaryTenants &&
                application.secondaryTenants[0]
              "
              label="Namn:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(secondaryTenants[0], 'name')
              "
              >{{ secondaryTenants[0].name }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.secondaryTenants &&
                application.secondaryTenants[0]
              "
              label="Personnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(secondaryTenants[0], 'ssn')
              "
              >{{ secondaryTenants[0].ssn }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.secondaryTenants &&
                application.secondaryTenants[0]
              "
              label="E-post:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(secondaryTenants[0], 'email')
              "
              >{{ secondaryTenants[0].email }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.secondaryTenants &&
                application.secondaryTenants[0]
              "
              label="Telefonnummer:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(secondaryTenants[0], 'phone')
              "
              >{{ secondaryTenants[0].phone }}</SummaryField
            >
            <SummaryField
              v-if="
                application &&
                application.secondaryTenants &&
                application.secondaryTenants[0]
              "
              label="Gatuadress:"
              :info-html="'Information saknas'"
              :has-empty-info-fields="
                isFieldMissing(secondaryTenants[0], 'address')
              "
              >{{ secondaryTenants[0].address }}</SummaryField
            >
          </div>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
        <SummaryBody
          v-if="
            application && application.secondaryTenants && secondaryTenants[1]
          "
          sub-heading="Andra personen på kontraktet"
        >
          <SummaryField
            v-if="
              application &&
              application.secondaryTenants &&
              application.secondaryTenants[1]
            "
            label="Namn:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="isFieldMissing(secondaryTenants[1], 'name')"
            >{{ secondaryTenants[1].name }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.secondaryTenants &&
              application.secondaryTenants[1]
            "
            label="Personnummer:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="isFieldMissing(secondaryTenants[1], 'ssn')"
            >{{ secondaryTenants[1].ssn }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.secondaryTenants &&
              application.secondaryTenants[1]
            "
            label="E-post:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="
              isFieldMissing(secondaryTenants[1], 'email')
            "
            >{{ secondaryTenants[1].email }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.secondaryTenants &&
              application.secondaryTenants[1]
            "
            label="Telefonnummer:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="
              isFieldMissing(secondaryTenants[1], 'phone')
            "
            >{{ secondaryTenants[1].phone }}</SummaryField
          >
          <SummaryField
            v-if="
              application &&
              application.secondaryTenants &&
              application.secondaryTenants[1]
            "
            label="Gatuadress:"
            :info-html="'Information saknas'"
            :has-empty-info-fields="
              isFieldMissing(secondaryTenants[1], 'address')
            "
            >{{ secondaryTenants[1].address }}</SummaryField
          >
        </SummaryBody>
      </Summary>
    </div>
    <div class="info-container">
      <p>
        <i>
          Säkerställ att du har läst igenom
          <button
            class="info-button"
            type="button"
            @click="subletInformationModalVisible = true"
          >
            informationen om andrahandsuthyrning
          </button>
          innan du skickar in din ansökan
        </i>
      </p>
      <InfoModal
        v-if="documentsInfoTextVisible && applicationIsEditable"
        class="documents-info-modal"
        title="Den här kategorin är låst"
        @click="documentsInfoTextVisible = false"
      >
        <p>
          För att kunna ladda upp filer behöver du ange ett <b>skäl</b> för
          andrahandsuthyrning.
        </p>
        <BaseButton
          :is-loading="false"
          :is-rounded="true"
          :is-fullwidth="isMobile"
          :is-small="isMobile"
          type="button"
          @click="closeModalAndRedirectToReason"
          ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
            Ange skäl
          </BaseIconText>
        </BaseButton>
      </InfoModal>
      <SubletInformationModal
        v-if="subletInformationModalVisible"
        :has-read-info="hasReadInfo"
        title="Information om uthyrning i andra hand"
        @closeInfoModal="subletInformationModalVisible = false"
      />
    </div>
    <BaseButton
      v-if="applicationIsEditable"
      :is-fullwidth="isMobile"
      :is-default="false"
      :is-rounded="true"
      :is-disabled="!applicationComplete"
      type="button"
      class="next-category-button"
      @click="submitApplicationModalVisible = true"
      >Skicka in för granskning</BaseButton
    >
    <InfoModal
      v-if="submitApplicationModalVisible"
      title="Bekräfta skicka in för granskning"
      :mobile-full-width="isMobile"
      :show-close-btn="false"
      confirm-label="Jag vill skicka in ansökan"
      abort-label="Avbryt"
      @click="confirmModalClick"
    >
      <p>
        Efter att du skickat in din ansökan kommer du inte att kunna göra några
        ändringar. Är du säker på att du vill du skicka in ansökan?
      </p></InfoModal
    >
  </SubletPage>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import subletRouter from '@/router/subletRouter';
import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import SubletPage from '@/components/SubletPage.vue';
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import SummaryField from '@/components/summary/SummaryField.vue';
import svgs from '@/utils/icon-constants';
import SubletInformationModal from '@/components/SubletInformationModal.vue';
import SubletDocumentsGrid from '@/components/SubletDocumentsGrid.vue';

export default {
  name: 'DataSummary',
  components: {
    InfoModal,
    BaseButton,
    SubletPage,
    BaseIconText,
    Summary,
    SummaryBody,
    SummaryField,
    SubletInformationModal,
    SubletDocumentsGrid
  },
  props: {
    application: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      submitApplicationModalVisible: false,
      subletInformationModalVisible: false,
      downloadingId: null,
      completedStatuses: ['COMPLETE', 'APPROVED'],
      documentsInfoTextVisible: false,
      svgs
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      menu: 'app/menu',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      hasReadInfo: 'subletApplication/hasReadInfo',
      rejectedCategories: 'subletApplication/rejectedCategories',
      requiredDocuments: 'subletApplication/requiredDocuments',
      noneRequiredDocuments: 'subletApplication/noneRequiredDocuments',
      applicationCategoriesWithErrors:
        'subletApplication/applicationCategoriesWithErrors',
      calculateMissingFields: 'subletApplication/calculateMissingFields',
      applicationComplete: 'subletApplication/applicationComplete'
    }),
    currentTenants() {
      return this.application?.currentTenants;
    },
    secondaryTenants() {
      return this.application?.secondaryTenants;
    },
    apartment() {
      return this.application?.apartment;
    },
    reason() {
      return this.application?.reason;
    },
    documents() {
      return this.application?.documents || [];
    },
    computedDocumentsGroups() {
      return this.application?.computedDocumentsGroups || {};
    },
    secondHouseOwner() {
      return this.application?.reason?.secondHouseOwner;
    },
    renderReasonTypeData() {
      if (!this.reason) {
        return null;
      }

      if (this.reason.type === 'sambo') {
        return 'Provsambo';
      } else if (this.reason.type === 'arbete') {
        return 'Arbete på annan ort eller utomlands';
      } else if (this.reason.type === 'studie') {
        return 'Studera på annan ort eller utomlands';
      } else {
        return 'Annan anledning';
      }
    },
    renderHousingTypeData() {
      if (!this.reason) {
        return null;
      }

      if (this.reason.housingType === 'hyresratt') {
        return 'Hyresrätt';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Bostadsrätt';
      } else {
        return 'Villa';
      }
    },

    documentsForSelectedReason() {
      const documentsToShow = [
        ...this.requiredDocuments,
        ...this.noneRequiredDocuments
      ];

      const filteredDocuments = this.documents.filter(doc =>
        documentsToShow.includes(doc.category)
      );

      return filteredDocuments;
    },

    calcSubletFurnished() {
      if (this.apartment?.furnished) {
        return 'Ja';
      } else {
        return 'Nej';
      }
    },
    calcMovingAbroad() {
      if (this.reason?.movingAbroad) {
        return 'Ja';
      } else {
        return 'Nej';
      }
    },
    calcPropertyOwner() {
      if (this.reason?.propertyOwner) {
        return 'Ja';
      } else {
        return 'Nej';
      }
    },

    changeBtnDisabled() {
      if (!this.application?.reason?.type) {
        return true;
      } else return false;
    },
    labelName() {
      if (this.reason.housingType === 'hyresratt') {
        return 'Hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Innehavare:';
      } else {
        return 'Ägare:';
      }
    },
    labelPhone() {
      if (this.reason.housingType === 'hyresratt') {
        return 'Telefonnummer till hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Telefonnummer till innehavare:';
      } else {
        return 'Telefonnummer till ägare:';
      }
    },
    labelEmail() {
      if (this.reason.housingType === 'hyresratt') {
        return 'E-post till hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'E-post till innehavare:';
      } else {
        return 'E-post till ägare:';
      }
    },
    subHeadingOwner() {
      if (this.reason.housingType === 'bostadsratt') {
        return 'Delägare / innehavare till boendet';
      } else if (this.reason.housingType === 'villa') {
        return 'Delägare / ägare till boendet';
      } else {
        return '';
      }
    },
    labelOwner() {
      if (this.reason.housingType === 'bostadsratt') {
        return 'Jag är delägare / innehavare:';
      } else if (this.reason.housingType === 'villa') {
        return 'Jag är delägare / ägare:';
      } else {
        return '';
      }
    },
    rejectedDocumentCategoryReason() {
      let categoriesInDocumentsGroups = Object.keys(
        this.computedDocumentsGroups
      );

      const rejectedDocuments = this.documents.filter(
        doc => doc.status === 'REJECTED' && doc.rejectReason
      );

      const rejectedDocumentsGroupsCategory =
        categoriesInDocumentsGroups.filter(
          category =>
            this.computedDocumentsGroups[category].status === 'REJECTED' &&
            this.computedDocumentsGroups[category].rejectReason
        );

      if (
        rejectedDocuments.length === 1 &&
        !rejectedDocumentsGroupsCategory.length
      ) {
        return rejectedDocuments[0].rejectReason;
      } else if (
        rejectedDocumentsGroupsCategory.length === 1 &&
        !rejectedDocuments.length
      ) {
        return this.computedDocumentsGroups[rejectedDocumentsGroupsCategory]
          .rejectReason;
      } else if (
        rejectedDocuments.length > 0 ||
        rejectedDocumentsGroupsCategory.length > 0
      ) {
        return 'Det finns flera kommentarer för den här kategorin. Gå till sidan "Ladda upp filer" för att läsa alla.';
      }
      return '';
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'app/goToNextCategory',
      submitApplication: 'subletApplication/submitApplication'
    }),
    closeModalAndGoToStatusPage() {
      this.submitApplicationModalVisible = false;
      this.goToNextCategory();
    },
    async confirmModalClick(eventType) {
      this.submitApplicationModalVisible = true;

      switch (eventType) {
        case 'CONFIRM':
          await this.submitApplication(this.application.id);
          this.closeModalAndGoToStatusPage();

          break;
        case 'REJECT':
          this.submitApplicationModalVisible = false;
          break;
        default:
          this.submitApplicationModalVisible = false;
      }
    },
    async goToCategory(category) {
      const currentCategory = category;

      const categoryToChange = this.menu.find(
        item => item.statusName === currentCategory
      );

      if (
        categoryToChange.statusName === 'DOCUMENTS' &&
        !this.application?.reason?.type
      ) {
        this.documentsInfoTextVisible = true;
      } else if (categoryToChange && categoryToChange.url) {
        await subletRouter.push({
          path: categoryToChange.url
        });
      }
    },
    blockDocumentsView(category) {
      const currentCategory = category;

      const categoryToChange = this.menu.find(
        item => item.statusName === currentCategory
      );

      if (
        categoryToChange.statusName === 'REASON' &&
        !this.application?.reason?.type
      ) {
        return true;
      }

      return false;
    },
    isValidDate(date) {
      const validDate = new Date(date);
      return !!date && validDate instanceof Date && !isNaN(validDate);
    },

    calculateFieldsWithError(category) {
      return Object.keys(category).filter(key => !category[key]);
    },

    isFieldMissing(category, fieldKey) {
      const missingFields = this.calculateMissingFields(category);
      return missingFields.includes(fieldKey);
    },
    fieldHasError(category, fieldKey) {
      const fieldsWithError = this.calculateFieldsWithError(category);
      return fieldsWithError.includes(fieldKey);
    },
    rejectedCategory(categories) {
      const rejectedCategory = categories.find(
        category => category.status === 'REJECTED' && category.rejectReason
      );

      if (rejectedCategory) {
        return rejectedCategory.rejectReason;
      } else {
        return '';
      }
    },

    closeModalAndRedirectToReason() {
      this.documentsInfoTextVisible = false;

      this.$router.push('/skal');
    }
  }
};
</script>

<style lang="scss" scoped>
.data-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  margin-bottom: 4.8rem;
  .more-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @media ($mobile) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.info-container {
  font-size: 1.5rem;
  margin-bottom: 2.8rem;

  .info-button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
