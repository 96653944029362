<template>
  <section class="status-text-wrapper">
    <div class="dynamic-text-container">
      <h1>Översikt</h1>
      <!-- <BaseImage
        v-if="
          (!firstApplicantDone && isSingleOwner) ||
          (isOwnerOfAllApplicants && !allApplicantsDone)
        "
        class="icon"
        :url="svgs.ICONS.BLUE_EXCLAMATION"
      />
      <BaseImage
        v-else-if="isSingleAndDone && !allApplicantsDone"
        class="icon"
        :url="svgs.ICONS.CHECK_GREEN_CIRCLE"
      /> -->
      <!-- <div v-else class="multiple-icon-container">
        <div v-for="applicant in applicants" :key="applicant.id">
          <BaseImage class="icon" :url="svgs.ICONS.CHECK_GREEN_CIRCLE" />
        </div>
      </div> -->
      <h3 v-if="allApplicantsDone">Vad händer nu?</h3>
      <p v-html="dynamicStatusText" />
      <p v-if="allApplicantsDone">Vi håller tummarna att ni får till bytet!</p>
    </div>
    <SwapSummary v-if="!!application" :application="application" />
    <BaseButton
      class="show-statuses-for-all-btn"
      :class="{
        'add-margin':
          (!firstApplicantDone && isSingleOwner) ||
          (isOwnerOfAllApplicants && !allApplicantsDone)
      }"
      :is-fullwidth="isMobile"
      :is-rounded="true"
      :is-dark="true"
      @click="showStatusModal = true"
    >
      <img :src="svgs.ICONS.CLIPBOARD" />
      <span class="button-text">Visa status för alla bytesparter</span>
    </BaseButton>
    <StatusModal v-if="showStatusModal" @close="showStatusModal = false" />
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { readableNames } from '@/utils/common';

import BaseButton from '@/components/BaseButton.vue';
//import BaseImage from '@/components/BaseImage.vue';
import svgs from '@/utils/icon-constants';
import StatusModal from '@/components/StatusModal.vue';
import SwapSummary from '@/components/SwapSummary/SwapSummary.vue';

export default {
  name: 'StatusView',
  components: {
    BaseButton,
    //BaseImage,
    StatusModal,
    SwapSummary
  },
  data() {
    return {
      svgs,
      showStatusModal: false,
      fetchingStatuses: false,
      completedStatuses: ['COMPLETE', 'APPROVED', 'COMMENT']
    };
  },
  computed: {
    ...mapState('application', ['application']),
    ...mapGetters({
      isMobile: 'app/isMobile',
      isLbMode: 'app/isLbMode',
      applicant: 'application/applicant',
      applicants: 'application/applicants',
      isOwnerOfAllApplicants: 'application/isOwnerOfAllApplicants',
      isSingleOwner: 'application/isSingleOwner',
      connectedLandlordApplicants: 'application/connectedLandlordApplicants',
      menuFlat: 'app/menuFlat',
      allSummarized: 'appValidation/allSummarized',
      eachApplicantSummarized: 'appValidation/eachApplicantSummarized'
    }),
    showNotCompleted() {
      if (
        !this.isOwnerOfAllApplicants &&
        !this.allApplicantsDone &&
        this.isSingleAndDone
      ) {
        return false;
      }

      if (this.allApplicantsDone) {
        return false;
      }
      return true;
    },
    applicantId() {
      if (!this.applicant) return null;

      return this.applicant.id;
    },
    singleApplicantLandlordConnected() {
      return this.application?.applicants[0].apartments[0].landlordId > 0;
    },
    allApplicantsDone() {
      return Object.values(this.allSummarized).every(status =>
        this.isOkStatus(status)
      );
    },
    isSingleAndDone() {
      return this.isSingleOwner && this.firstApplicantDone;
    },
    // first applicant is always the one signed in
    firstApplicantDone() {
      if (!this.eachApplicantSummarized?.length) {
        return false;
      }
      return Object.values(this.eachApplicantSummarized[0]).every(status =>
        this.isOkStatus(status)
      );
    },
    dynamicStatusText() {
      const { connected, notConnected } = this.connectedLandlordApplicants;

      const connectedPlural = connected.length > 1;
      const notConnectedPlural = notConnected.length > 1;

      // Everything within LbMode
      if (this.isLbMode) {
        if (this.isSingleAndDone && !this.allApplicantsDone) {
          return 'Det ser ut som att du är klar med din del i den här bytesansökan! De andra bytesparterna är också på god väg att bli klara. När alla är klara <strong>skickar vi in ansökan till era hyresvärdar</strong> eftersom de är anslutna till oss.';
        } else if (this.allApplicantsDone) {
          return 'Efter att alla parter har signerat tar våra handläggare hand om er ansökan och skickar in bytet till era hyresvärdar. När hyresvärdarna tagit emot ansökan kan det ta upp till 3 månader innan beslut tas.';
        }
      }
      // Not LB MODE
      else {
        if (
          !this.isOwnerOfAllApplicants &&
          !this.allApplicantsDone &&
          this.isSingleAndDone
        ) {
          if (this.singleApplicantLandlordConnected) {
            return 'Det ser ut som att du är klar med din del i den här bytesansökan! De andra bytesparterna är också på god väg att bli klara. När alla är klara <strong>skickar vi in ansökan till din hyresvärd</strong> eftersom denne är ansluten till oss.';
          } else {
            return 'Det ser ut som att du är klar med din del i den här bytesansökan. De andra bytesparterna är också på god väg att bli klara. <strong>OBS!</strong> Kom även ihåg att ansöka om byte hos din hyresvärd.';
          }
        } else if (this.allApplicantsDone) {
          if (notConnected.length > 0 && connected.length > 0) {
            return `Efter att alla parter har signerat tar våra handläggare hand om er ansökan och skickar in bytet till ${readableNames(
              connected,
              true,
              true
            )} ${
              connectedPlural ? `hyresvärdar` : `hyresvärd`
            } eftersom deras ${
              connectedPlural
                ? `hyresvärdar är anslutna`
                : `hyresvärd är ansluten`
            } till oss. ${readableNames(notConnected, true, true)} ${
              notConnectedPlural ? `hyresvärdar` : `hyresvärd`
            } använder inte denna tjänst och behöver därför <strong>utöver detta ansöka om ett byte även hos dem</strong>. När hyresvärdarna tagit emot ansökan och alla handlingar är inskickade från samtliga hyresgäster, kan det ta upp till 3 månader innan beslut tas.`;
          } else if (connected.length > 0) {
            return `Efter att alla parter har signerat tar våra handläggare hand om er ansökan och skickar in bytet till ${readableNames(
              connected,
              true,
              true
            )} ${
              connectedPlural ? `hyresvärdar` : `hyresvärd`
            } eftersom <strong>${connectedPlural ? `dessa` : `denna`} ${
              connectedPlural
                ? `hyresvärdar är anslutna`
                : `hyresvärd är ansluten`
            } till oss</strong>. När hyresvärdarna tagit emot ansökan och alla handlingar är inskickade från samtliga hyresgäster, kan det ta upp till 3 månader innan beslut tas.`;
          } else if (notConnected.length > 0) {
            return 'Efter att alla parter har signerat tar våra handläggare hand om er ansökan. Eftersom era hyresvärdar inte använder denna tjänst behöver ni därför <strong>utöver detta ansöka om ett byte även hos dem</strong>. När hyresvärdarna tagit emot ansökan och alla handlingar är inskickade från samtliga hyresgäster, kan det ta upp till 3 månader innan beslut tas.';
          }
        }
      }

      return '';
    }
  },
  async created() {
    this.fetchingStatuses = true;

    await this.syncApplicationStatuses();

    this.fetchingStatuses = false;
  },
  methods: {
    ...mapActions('application', ['syncApplicationStatuses']),
    isOkStatus(status) {
      return status === '' || status === 'COMPLETE' || status === 'APPROVED';
    },
    getLinkFromMenu(category) {
      return this.menuFlat.find(link => link.statusName === category) || {};
    },
    getLinkForCategory(category) {
      return this.getLinkFromMenu(category)?.url || '';
    },
    getLabelForCategory(category) {
      return this.getLinkFromMenu(category)?.label || '';
    }
  }
};
</script>

<style lang="scss" scoped>
.status-text-wrapper {
  .dynamic-text-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 2rem;
    .icon {
      width: 40px;
    }
    .multiple-icon-container {
      display: flex;
      gap: 10px;
    }
    .dynamic-title {
      text-transform: uppercase;
    }
    .category-link-container {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }

    .category-link {
      color: var(--color-sky-blue);
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
    }
    .category-link:hover {
      color: var(--color-blue);
    }
  }
  p:empty {
    display: none;
  }
}
.show-statuses-for-all-btn {
  :deep(.slot-wrapper) {
    span {
      margin-left: 10px;
    }
  }
  &.add-margin {
    margin-top: 30px;
  }
}
</style>
