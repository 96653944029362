import swapRouter from '@/router/swapRouter';
import auth from '@/services/auth';
import applicationService from '@/services/applicationService';
import tutorialService from '@/services/tutorialService';
import { getQueryParams } from '@/utils/windowUtils';

const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
const smallDesktopQuery = window.matchMedia(
  '(min-width: 769px) and (max-width: 1280px)'
);

async function initiate(ctx) {
  ctx.commit('setIsInitiating', true);
  ctx.dispatch('getAndsetScreenSize');

  if (!ctx.rootState.application.applicationId) {
    const localStorageApplicationId = localStorage.getItem('applicationId');
    if (localStorageApplicationId) {
      ctx.commit('application/setApplicationId', localStorageApplicationId, {
        root: true
      });
    }
  }

  const { invite, applicantId } = getQueryParams();
  const applicationId = applicantId
    ? applicantId.split('-').slice(1).join('-')
    : '';

  if (invite) {
    ctx.commit('app/setInviteCode', invite, { root: true });
  }

  if (applicationId) {
    ctx.commit('application/setApplicationId', applicationId, {
      root: true
    });
  }

  const valid = await auth.validateSession();
  if (valid) {
    if (ctx.state.inviteCode) {
      await ctx.dispatch(
        'application/fetchApplicationIdFromInvite',
        ctx.state.inviteCode,
        {
          root: true
        }
      );
    }

    const result = await ctx.dispatch(
      'application/fetchSelectedApplication',
      null,
      {
        root: true
      }
    );

    const applicationStarted = result
      ? result.applicants.every(applicant => applicant.started)
      : false;

    const applicant = ctx.rootGetters['application/applicant'];
    const applicantStarted = applicant?.started;

    const applicationIsInactive = result.status?.description === 'INACTIVE';
    const notInActivatedByCurrentApplicant = result
      ? result.applicants[0].id !== result.inactivatedBy
      : true;

    if (!result) {
      await swapRouter.push('/valj-byte');
    } else if (applicationIsInactive && notInActivatedByCurrentApplicant) {
      await ctx.dispatch('reset');
      return;
    } else if (!applicantStarted) {
      await swapRouter.push('/byteskedja');
    } else if (result && applicationStarted) {
      await swapRouter.push('/status');
    } else if (result) {
      await swapRouter.push('/byteskedja');
    }

    await ctx.dispatch('syncTutorialSteps');
  } else {
    clearUserLocalStorage();

    await swapRouter.push(`/logga-in`);
  }

  await ctx.dispatch('configureSupportAddress');

  ctx.commit('setInitData', {
    appReady: true,
    authenticated: valid,
    account: null
  });

  ctx.commit('setIsInitiating', false);
}

function clearUserLocalStorage() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');
}

async function logout(ctx) {
  localStorage.clear();
  await ctx.dispatch('reset');
}

async function clearInviteAndReset(ctx) {
  ctx.commit('app/setInviteCode', undefined, { root: true });
  await ctx.dispatch('reset');
}

async function reset(ctx) {
  ctx.commit('application/setApplication', null, { root: true });
  ctx.commit('application/setApplicationId', null, { root: true });
  ctx.commit('application/setSelectedApplicantIndex', 0, { root: true });
  ctx.commit('application/setSelectedPossessionIndex', 0, { root: true });
  await ctx.dispatch('initiate');
}

async function getAndsetScreenSize({ state, commit }) {
  let newSize = 'DESKTOP';

  if (mobileMediaQuery.matches) {
    newSize = 'MOBILE';
  } else if (smallDesktopQuery.matches) {
    newSize = 'DESKTOP_SMALL';
  }

  if (state.setScreenSize !== newSize) {
    commit('setScreenSize', newSize);
    window.dispatchEvent(new CustomEvent('new-size', { detail: newSize }));
  }
}

async function getSubdomain() {
  let domain = window.location.host;
  if (domain && domain === 'formular-demo.bytesansokan.se')
    domain = 'henrik.bytesansokan.se';
  const arr = domain.split('.');
  if (arr.length < 2) {
    return null;
  }

  return arr[0];
}

async function fetchLandlord(ctx) {
  let landlord = null;

  const subdomain = await ctx.dispatch('getSubdomain');

  if (subdomain && subdomain !== 'formular' && subdomain !== 'formular-demo') {
    landlord = await applicationService.getLandLord(subdomain);
  }

  ctx.commit('setLandlord', landlord);
  return landlord;
}

async function configureSupportAddress(ctx) {
  const subdomain = await ctx.dispatch('getSubdomain');
  if (subdomain && subdomain === 'formular') {
    ctx.commit('setSupportAddress', 'bytesansokan@lagenhetsbyte.se');
  }
}

function getNextPath(ctx) {
  const currentIndex = ctx.getters.menuFlat.findIndex(
    item => item.url === swapRouter.currentRoute.path
  );

  const next = ctx.getters.menuFlat[currentIndex + 1];
  if (next) {
    return next.url;
  }

  return ctx.getters.menuFlat[0].url;
}

async function syncTutorialSteps(ctx) {
  let seenTutorials = await tutorialService.getUserTutorialsSeen();

  if (!seenTutorials) {
    return;
  }

  // We clear seen tutorials on seen to enable correct counting when
  // new tutorials are present (1 of 1 on new visits instead of 3 of 3)
  for (let tutorialId of seenTutorials) {
    ctx.commit('clearSyncedTooltips', tutorialId);
  }
}

async function markTooltipAsSeen(ctx, { reference }) {
  if (!reference) {
    return;
  }

  ctx.commit('markTooltipAsSeen', reference);

  await tutorialService.addSeenTutorialStep(reference);
}

function toggleSideMenuVisible(ctx, show) {
  const body = document.querySelector('body');
  if (show) {
    this.scrollPosition = window.pageYOffset;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${this.scrollPosition}px`;
    body.style.width = '100%';
    ctx.commit('setSideMenuVisible', true);
  } else {
    ctx.commit('setSideMenuVisible', false);
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    window.scrollTo(0, this.scrollPosition);
  }
}

async function goToNextSection(ctx, listCheck = null) {
  const nextValidApplicantIndex =
    ctx.rootGetters['application/nextValidApplicantIndex'];
  const selectedPossessionIndex =
    ctx.rootState.application.selectedPossessionIndex;

  if (selectedPossessionIndex < listCheck) {
    ctx.commit(
      'application/setSelectedPossessionIndex',
      selectedPossessionIndex + 1,
      {
        root: true
      }
    );
  } else if (nextValidApplicantIndex > 0) {
    ctx.commit('application/setSelectedPossessionIndex', 0, { root: true });
    ctx.commit(
      'application/setSelectedApplicantIndex',
      nextValidApplicantIndex,
      { root: true }
    );
  } else {
    const nextPath = await ctx.dispatch('getNextPath');
    await swapRouter.push({
      path: nextPath
    });
  }
}

export default {
  initiate,
  getAndsetScreenSize,
  reset,
  logout,
  fetchLandlord,
  configureSupportAddress,
  getNextPath,
  getSubdomain,
  syncTutorialSteps,
  markTooltipAsSeen,
  toggleSideMenuVisible,
  clearInviteAndReset,
  goToNextSection
};
