<template>
  <form ref="formEl" class="form" novalidate @submit.prevent="submit">
    <slot />
    <BaseButton
      :is-loading="false"
      :is-rounded="true"
      :is-disabled="isDisabled"
      :is-fullwidth="isMobile"
      :is-small="isMobile"
      type="submit"
      ><BaseIconText
        v-if="!isDisabled"
        :icon-url="svgs.ICONS.ARROW_RIGHT"
        :align-right="true"
      >
        {{ submitButtonText }}
      </BaseIconText>
      <span v-else>{{ submitButtonText }}</span>
    </BaseButton>
    <input
      type="submit"
      style="position: absolute; left: -9999px; width: 1px; height: 1px"
      tabindex="-1"
    />
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import svgs from '@/utils/icon-constants';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton,
    BaseIconText
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      svgs
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsSent: 'application/applicationIsSent'
    }),
    submitButtonText() {
      if (this.isLoading) {
        return 'Sparar...';
      }

      if (this.formHasChanged) {
        return 'Spara och fortsätt';
      }

      return 'Spara och fortsätt';
    },
    isDisabled() {
      if (!this.formHasChanged || this.isLoading || this.applicationIsSent) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$refs.formEl.addEventListener('input', this.emitEditing);
  },
  beforeDestroy() {
    this.$refs.formEl.removeEventListener('input', this.emitEditing);
  },
  methods: {
    submit() {
      if (this.isDisabled) {
        return;
      }
      this.$emit('subletFormSubmit');
    },
    emitEditing() {
      this.$emit('isEditing', true);
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  width: 100%;
}
</style>
