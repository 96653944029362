<template>
  <SubletPage v-if="apartment" :categories="[apartment]">
    <SubletForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      v-on="$listeners"
    >
      <SubletInput
        v-model="apartment.address"
        label="Adress"
        placeholder="Adress"
        description="Ange lägenhetens adress"
        autocomplete="street-address"
      />
      <Fieldset>
        <SubletInput
          v-model="postalCode"
          :class="{ autocomplete: postalCodeCompleted }"
          label="Postnummer"
          placeholder="Postnummer"
          description="Ange lägenhetens postnummer"
          :validation="validatePostalCode"
          autocomplete="postal-code"
          :dropdown="postalCodeDropdown.length > 0 ? postalCodeDropdown : null"
          error-text="Postnummer måste anges med endast 5 siffror"
          :is-loading="isSearchingPostalCode"
          @input="postalCodeCompleted = false"
          @dropdownClick="postalCodeDropdownClick"
        />
        <SubletInput
          v-model="apartment.city"
          :class="{ autocomplete: postalCodeCompleted }"
          label="Ort"
          placeholder="Ort"
          description="Ange vilken ort lägenheten ligger i"
          autocomplete="address-level2"
          @input="postalCodeCompleted = false"
        />
        <SubletInput
          v-model="apartment.objectNumber"
          label="Objektnummer/kontraktsnummer"
          placeholder="Objektnummer/kontraktsnummer"
          description="Ange lägenhetens objektnummer/kontraktsnummer"
        >
          <InfoButtonModal title="Objektnummer/kontraktsnummer">
            <p>
              Objektsnumret är det nummer som din lägenhet har i hyresvärdens
              system. Det består ofta av åtta siffror xxxx-xxxx, där de första
              fyra siffrorna är relaterade till den fastighet du bor i och de
              fyra sista visar vilken lägenhet det är.
            </p>

            Objektsnumret hittar du oftast på:
            <ul>
              <li>Utsidan av din lägenhetsdörr</li>
              <li>Namntavlan i ditt trapphus</li>
              <li>Hyreskontraktet eller på hyresräkningen</li>
            </ul>
          </InfoButtonModal>
        </SubletInput>
        <SubletInput
          v-model="apartment.apartmentNumber"
          label="Lägenhetsnummer"
          placeholder="Lägenhetsnummer"
          description="Ange lägenhetsnumret"
          error-text="Fältet får endast innehålla siffror"
          :validation="validateNumber"
          inputmode="numeric"
          pattern="[0-9]*"
        />
      </Fieldset>
      <Fieldset>
        <SubletInput
          v-model="apartment.rent"
          label="Nuvarande hyra"
          placeholder="Nuvarande hyra"
          description="Ange den av hyresvärden fastställda hyran"
          error-text="Fältet får endast innehålla siffror"
          :validation="validateNumber"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <Checkbox
          v-model="subletFurnished"
          class="furnished-checkbox"
          name="subletFurnished"
          :is-sublet="true"
          :description="'Jag kommer att hyra ut lägenheten möblerad'"
          :checked="subletFurnished"
          :is-disabled="editingDisabled"
          @change="handleSubletFurnishedToggle"
        />
      </Fieldset>
    </SubletForm>
  </SubletPage>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePostalCode
} from '@/utils/input-validations';
import { generateHighlightedHtml } from '@/utils/common';
import applicationService from '@/services/applicationService';
import Fieldset from '@/components/Fieldset.vue';
import SubletInput from '@/components/SubletInput.vue';
import SubletForm from '@/components/SubletForm.vue';
import SubletPage from '@/components/SubletPage.vue';
import InfoButtonModal from '@/components/InfoButtonModal.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'Apartment',
  components: {
    Fieldset,
    SubletInput,
    SubletForm,
    SubletPage,
    InfoButtonModal,
    Checkbox
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      validEmail,
      validateNumber,
      validatePostalCode,
      autoCompletePostalCodeResult: [],
      isSearchingPostalCode: false,
      postalCodeCompleted: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      rejectedCategories: 'subletApplication/rejectedCategories'
    }),
    apartmentCategoryRejected() {
      return this.rejectedCategories.includes('apartment');
    },
    editingDisabled() {
      return !this.applicationIsEditable && !this.apartmentCategoryRejected;
    },
    apartment: {
      get() {
        return this.application?.apartment;
      },
      set() {
        this.$emit();
      }
    },
    postalCode: {
      get() {
        return this.apartment?.postalCode || '';
      },
      set(value) {
        if (this.apartment) {
          this.apartment.postalCode = value;
        }
      }
    },
    postalCodeDropdown() {
      return generateHighlightedHtml(
        this.autoCompletePostalCodeResult,
        this.postalCode
      );
    },
    subletFurnished: {
      get() {
        return this.apartment?.furnished || false;
      },
      set(newValue) {
        if (this.apartment) {
          this.$set(this.apartment, 'furnished', newValue);
        }
      }
    }
  },
  watch: {
    postalCode(val) {
      if (val?.length > 1) {
        this.searchPostalCodeDebounced(val);
      } else {
        this.skipPostalCodeSearch = false;
        this.searchPostalCodeDebounced.cancel();
        this.autoCompletePostalCodeResult = [];
      }
    }
  },
  created() {
    this.searchPostalCodeDebounced = debounce(this.searchPostalCode, 300);
  },
  methods: {
    async searchPostalCode(query) {
      this.isSearchingPostalCode = true;
      const result = await applicationService.searchPostalCode(query);
      this.autoCompletePostalCodeResult = result.filter(
        result => result.name.length === 5
      );
      this.isSearchingPostalCode = false;
    },
    postalCodeDropdownClick(value) {
      const match = this.autoCompletePostalCodeResult.find(
        result => result.name === value
      );
      if (!!match && this.apartment) {
        this.postalCodeCompleted = true;
        this.apartment.postalCode = match.name;
        this.apartment.city = match.description;
        this.searchPostalCodeDebounced.cancel();
      }
    },
    handleSubletFurnishedToggle() {
      this.subletFurnished = !this.subletFurnished;
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete :deep(input) {
  background-color: #e8f0fe;
}
.furnished-checkbox {
  margin-bottom: 0;
  @media ($mobile) {
    margin-bottom: 4.8rem;
  }
}
</style>
