<template>
  <SwapDefaultPage
    :possessions="
      !useSplitDocumentUpload('CREDIT_INFORMATION')
        ? []
        : getMembersListForTab(applicant, selectedPossessionIndex)
    "
    @applicantClick="handleApplicantClick"
    @possessionClick="setSelectedPossessionIndex"
  >
    <RejectionMessageCardDocs
      type="CREDIT_INFORMATION"
      :invalid-documents="invalidDocuments"
      :is-new-rejection="isNewRejection"
    />
    <!-- <transition mode="out-in" name="accordion-fade">
      <CollapseCard :is-alert="true" class="mb-2">
        <h3 slot="title-text">Driftstörningar hos MinUC.se</h3>
        <div slot="body-text" class="comments-body">
          <p>
            Just nu har Min UC driftproblem. Det finns andra tjänster att hämta
            kreditupplysningar ifrån, exempelvis CreditSafe
          </p>
          <p>
            För att hämta kreditupplysningar från CreditSafe, läs mer här:
            <br />
            <a
              target="_blank"
              href="https://www.creditsafe.com/se/sv/kontakt/vanliga-fragor.html"
              >https://www.creditsafe.com/se/sv/kontakt/vanliga-fragor.html</a
            >
          </p>
        </div>
      </CollapseCard>
    </transition> -->
    <CollapseCard
      :closed-identifier="'credit_info_modal_closed'"
      :is-primary="true"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <p>Här laddar ni upp kreditupplysningar från MinUC.</p>
        <br />
        <p>
          För att säkerställa att rätt förutsättningar finns för att kunna byta
          sin bostad kräver hyresvärdarna en kreditupplysning på
          <strong
            >samtliga som är tänkta att stå skrivna på det nya
            hyreskontraktet.</strong
          >
        </p>
        <br />
        <p>
          Du laddar enkelt ned en kreditupplysning via tjänsten "Min upplysning"
          på minuc.se (kostnad 39kr/ person)
        </p>
        <p>
          Observera att kreditupplysningen måste inhämtas personligen från
          MinUC. <strong>Inga andra kreditupplysningar godkänns.</strong>
        </p>
        <br />
        <h2 class="mb-1">Frågor och svar</h2>
        <h3>Varför måste jag ladda upp en kreditupplysning?</h3>
        <p>
          För att säkerställa att samtliga bytesparter har rätt förutsättningar
          för att kunna byta. Detta är en trygghet för dig som bytespart och
          ökar förutsättningarna för ett smidigt byte.
        </p>
        <br />
        <h3>
          Jag har redan en kreditupplysning från ett annat företag än MinUC.
          Varför godkänner ni inte den?
        </h3>
        <p>
          Allt fler hyresvärdar kräver att man bifogar en kreditupplysning från
          just MinUC i sin bytesansökan. Detta är inget som vi på Bytesansökan
          kan påverka.
        </p>
        <br />
        <h3>Varför måste jag själv hämta en kreditupplysning?</h3>
        <p>Många hyresvärdar kräver att denna inhämtas personligen.</p>
        <br />
        <h3>
          Min make / maka / sambo står på kontraktet idag men ska inte flytta
          med. Måste denne ändå ladda upp en kreditupplysning?
        </h3>
        <p>
          Nej, det gäller endast de som önskar stå på det nya hyreskontraktet.
        </p>
        <br />
        <h3>
          Hjälp! Jag har en eller flera betalningsanmärkningar. Kommer jag inte
          att kunna byta nu?
        </h3>
        <p>
          Detta är något som man måste titta på individuellt. Kontakta oss för
          vidare dialog på
          <a :href="`mailto:${getSupportAddress}`">
            {{ getSupportAddress }}
          </a>
        </p>
        <br />
        <p>Tänk på att:</p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          <strong>Tips:</strong> För att fota dokument på bästa sätt
          rekommenderar vi att du använder dig av en app som scannar av
          dokument. Exempel på appar som fungerar bra till både iOS och Android
          är Adobe Scan och Genius Scan, båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a :href="`mailto:${getSupportAddress}`" target="_blank">
            {{ getSupportAddress }}
          </a>
        </p>
      </template>
    </CollapseCard>

    <SwapDocumentUpload
      type="CREDIT_INFORMATION"
      :invalid-documents="invalidDocuments"
    ></SwapDocumentUpload>
  </SwapDefaultPage>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { getInvalidDocuments, getMembersListForTab } from '@/utils/common';

import SwapDocumentUpload from '@/components/SwapDocumentUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import RejectionMessageCardDocs from '@/components/RejectionMessageCardDocs.vue';

export default {
  name: 'CreditInformation',

  components: {
    SwapDocumentUpload,
    CollapseCard,
    SwapDefaultPage,
    RejectionMessageCardDocs
  },
  data() {
    return {
      getMembersListForTab
    };
  },
  computed: {
    ...mapGetters({
      applicant: 'application/applicant',
      getSupportAddress: 'app/getSupportAddress',
      useSplitDocumentUpload: 'application/useSplitDocumentUpload'
    }),
    ...mapState({
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      application: state => state.application.application,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    invalidDocuments() {
      if (!this.isNewRejection) {
        return {};
      }
      const documents =
        this.applicant?.documentGroups[this.selectedPossessionIndex]
          .CREDIT_INFORMATION.documents || [];
      return getInvalidDocuments(documents);
    },
    isNewRejection() {
      return !!this.applicant?.newRejections.documentGroups[
        this.selectedPossessionIndex
      ].CREDIT_INFORMATION;
    }
  },
  methods: {
    ...mapMutations({
      setSelectedApplicantIndex: 'application/setSelectedApplicantIndex',
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),

    handleApplicantClick(index) {
      this.setSelectedApplicantIndex(index);
      this.setSelectedPossessionIndex(0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>
<style></style>
