import apiClient from '@/services/apiClient';
import axios from 'axios';
import auth from './auth';

export async function storePrivateFile(applicantId, file, type, index) {
  try {
    const formData = new FormData();
    formData.append('applicantId', applicantId);
    formData.append('type', type);
    formData.append('file', file);
    formData.append('index', index);

    const authHeader = await auth.getAuthHeader();
    const { data } = await axios.post(
      `${apiClient.apiEndpoint}/portal/documents`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader
        }
      }
    );

    return data;
  } catch (e) {
    console.error(e);
  }

  return null;
}

export async function deletePrivateFile(applicantId, documentUuid) {
  try {
    const authHeader = await auth.getAuthHeader();
    const { data } = await axios.delete(
      `${apiClient.apiEndpoint}/portal/documents/${applicantId}/file/${documentUuid}`,
      {
        headers: authHeader
      }
    );

    return data;
  } catch (e) {
    console.error(e);
  }

  return null;
}

export async function getPrivateFile(applicantId, documentUuid) {
  try {
    const authHeader = await auth.getAuthHeader();
    const { data } = await axios.get(
      `${apiClient.apiEndpoint}/portal/documents/${applicantId}/file/${documentUuid}`,
      {
        headers: {
          ...authHeader,
          'content-type': 'text/plain',
          'content-transfer-encoding': 'base64'
        }
      }
    );

    return data;
  } catch (e) {
    console.error(e);
  }

  return null;
}
