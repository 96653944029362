import applicationService from '@/services/applicationService';
import cloneDeep from 'lodash.clonedeep';

async function fetchApplicationIdFromInvite(ctx, inviteCode) {
  const application =
    await applicationService.getApplicationFromInvite(inviteCode);

  if (application?.applicationId) {
    ctx.commit('setApplicationId', application.applicationId);
  }
}

async function fetchSelectedApplication(ctx) {
  const applicationId = ctx.state.applicationId;
  if (!applicationId) {
    return false;
  }

  const application = await applicationService.getApplication(applicationId);
  if (!application) {
    localStorage.removeItem('applicationId');
    return false;
  }
  ctx.commit('setApplication', application);
  return application;
}

async function syncApplicationStatuses(ctx) {
  const applicantId = ctx.getters.applicant?.id;
  const application = ctx.state.application;

  if (!applicantId || !application) {
    return;
  }

  const statuses = await applicationService.getApplicationStatuses(applicantId);

  if (!statuses) {
    return;
  }

  for (const id in statuses.applicants) {
    const status = statuses.applicants[id];
    ctx.commit('setApplicantStatus', { id, status });
  }

  ctx.commit('setApplicationStatus', statuses.application);
}

function getApplicantById(ctx, applicantId) {
  return ctx.state.application.applicants.find(
    applicant => applicant.id === applicantId
  );
}

async function addDocument(
  ctx,
  { fileName, uuid, createdAt, category, applicantId, memberIndex }
) {
  // Do not use applicant getter since that could have changed (eg. tab click while async)
  // and have a different applicantId compared to the applicantId param
  const applicantCopy = cloneDeep(getApplicantById(ctx, applicantId));

  const newDocument = {
    memberId: applicantCopy.members[0].id,
    fileName,
    uuid: uuid,
    type: category,
    createdAt: createdAt,
    memberIndex
  };

  applicantCopy.newRejections.documentGroups[memberIndex][category] = null;
  applicantCopy.documentGroups[memberIndex][category].documents.push(
    newDocument
  );
  ctx.commit('updateApplicant', {
    applicantId: applicantId,
    newRejections: applicantCopy.newRejections,
    documentGroups: applicantCopy.documentGroups
  });
}

async function removeDocument(ctx, { type, uuid, applicantId }) {
  // Do not use applicant getter since that could have changed (eg. tab click while async)
  // and have a different applicantId compared to the applicantId param
  const applicant = getApplicantById(ctx, applicantId);
  const newDocumentGroups = applicant.documentGroups.map(documentGroup => {
    if (documentGroup[type].documents.find(doc => doc.uuid === uuid)) {
      return {
        ...documentGroup,
        [type]: {
          ...documentGroup[type],
          documents: documentGroup[type].documents.filter(x => x.uuid !== uuid)
        }
      };
    }
    return documentGroup;
  });

  ctx.commit('updateApplicant', {
    applicantId: applicantId,
    documentGroups: newDocumentGroups
  });
}

async function startApplicant(ctx, applicantId) {
  await applicationService.startApplicant(applicantId);
  if (ctx.getters.isOwnerOfAllApplicants) {
    ctx.state.application.applicants.forEach(applicant => {
      ctx.commit('updateApplicant', {
        applicantId: applicant.id,
        status: 'ACTIVE',
        started: true
      });
    });
  } else {
    ctx.commit('updateApplicant', {
      applicantId: applicantId,
      status: 'ACTIVE',
      started: true
    });
  }
}

export default {
  removeDocument,
  fetchApplicationIdFromInvite,
  fetchSelectedApplication,
  addDocument,
  syncApplicationStatuses,
  startApplicant
};
