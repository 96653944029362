import { getGenitive, getGenitiveNameFallback } from './genitive';

export function getFilename(text) {
  text = decodeURI(text);

  let index = text.indexOf('&#');
  if (index === -1) {
    index = text.indexOf('%26%23');
  }

  if (index === -1) {
    return text;
  }

  return text.substring(0, index);
}

export function generateHighlightedHtml(resultArray, query = '') {
  if (!resultArray?.length) {
    return [];
  }

  return resultArray.map(result => {
    let highlightText = (result.name || '').toLowerCase();
    const index = highlightText.indexOf((query || '').toLowerCase());
    if (index >= 0) {
      highlightText = `${result.name.substring(
        0,
        index
      )}<strong>${result.name.substring(
        index,
        index + query.length
      )}</strong>${result.name.substring(index + query.length)}`;
    } else {
      highlightText = result.name;
    }

    return {
      value: result.name,
      label: highlightText,
      points: index + query.length
    };
  });
}
export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export function getInitials(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  // Split and filter out empty strings
  const splitName = name.split(' ').filter(str => !!str);

  if (!splitName.length) {
    return '';
  }

  if (splitName.length > 1) {
    // Get first and last
    return `${splitName[0][0].toUpperCase()}${splitName[
      splitName.length - 1
    ][0].toUpperCase()}`;
  }

  // Single return
  return splitName[0][0].toUpperCase();
}

function genitiveForLastName(name, isLast) {
  return isLast ? getGenitive(name) : name;
}

export function readableNames(names, bold = false, withGenitive = false) {
  const namesSize = names.length - 1;
  const firstName =
    0 === namesSize ? genitiveForLastName(names[0], withGenitive) : names[0];

  let namesStr = bold ? `<b>${firstName}</b>` : firstName;

  for (let i = 1; i < names.length; i++) {
    const currentIsLast = i === namesSize;
    let currentName = names[i];

    if (currentIsLast && withGenitive) {
      currentName = getGenitive(currentName);
    }

    currentName = bold ? `<b>${currentName}</b>` : currentName;

    if (i === names.length - 1) {
      namesStr += ` och ${currentName}`;
    } else {
      namesStr += `, ${currentName}`;
    }
  }
  return namesStr;
}

export function getInvalidDocuments(documents = []) {
  return documents
    .filter(document => !!document.review?.message)
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: {
          fileName: getFilename(curr.fileName),
          message: curr.review.message
        }
      }),
      {}
    );
}

export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
}

export function getDateAMonthFromCurrentDate(monthsToAdd) {
  const today = new Date();
  today.setMonth(today.getMonth() + monthsToAdd);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
}

export function getMembersListForTab(applicant, selectedPossessionIndex) {
  if (!applicant?.members) {
    return [];
  }
  return applicant.members.map((member, index) => ({
    title: member.name || 'Medsökande',
    active: selectedPossessionIndex === index,
    tabLink: index,
    classes: !member.name ? ['default-value'] : undefined
  }));
}

export function getAddressDescription(applicant, index, possessionIndex) {
  let letter = alphabet[index];

  if (!applicant.apartments[possessionIndex]) {
    possessionIndex = 0;
  }

  if (!applicant.apartments[possessionIndex].address) {
    return `${letter}${possessionIndex + 1}`;
  }

  return `${letter}${possessionIndex + 1} (${
    applicant.apartments[possessionIndex].address
  })`;
}

export function getActiveMemberSwapAddresses(applicants, applicantIndex) {
  if (!applicants.length) {
    return null;
  }
  let nextIndex = applicantIndex + 1;
  if (nextIndex > applicants.length - 1) {
    nextIndex = 0;
  }

  return applicants[nextIndex].apartments.map((apartment, index) => {
    let numerator =
      applicants[nextIndex].apartments.length < 2 ? '' : index + 1;

    if (!apartment.address && !applicants[nextIndex].members[index].name) {
      return `Lägenhet ${alphabet[nextIndex]}${numerator}`;
    } else if (!apartment.address) {
      return `Lägenhet ${
        alphabet[nextIndex]
      }${numerator} (${getGenitiveNameFallback(
        applicants[nextIndex].members[index].name
      )} Lägenhet)`;
    }

    return `Lägenhet ${alphabet[nextIndex]}${numerator} (${apartment.address})`;
  });
}

export function translateCategory(category) {
  return (
    {
      APARTMENTS: 'apartments',
      MEMBERS: 'members',
      SWAPDETAILS: 'swapDetails',
      SWAPREASON: 'swapReason'
    }[category] || category
  );
}
export function hasNewPropertyRejections(
  applicants,
  applicantIndex = 0,
  possessionIndex = 0,
  category
) {
  switch (category) {
    case 'APARTMENTS':
    case 'MEMBERS':
    case 'SWAPDETAILS':
      return !!applicants[applicantIndex].newRejections[
        translateCategory(category)
      ][possessionIndex];
    case 'SWAPREASON':
      return applicants[applicantIndex].newRejections.swapReason;
    default:
      return applicants[applicantIndex].newRejections.documentGroups[
        possessionIndex
      ][category];
  }
}

export default {
  getFilename,
  generateHighlightedHtml,
  getInitials,
  readableNames,
  getInvalidDocuments,
  getCurrentDate,
  getDateAMonthFromCurrentDate,
  getMembersListForTab
};
