<template>
  <SwapDefaultPage
    :possessions="
      !useSplitDocumentUpload('FAMILY_CERTIFICATE')
        ? []
        : getMembersListForTab(applicant, selectedPossessionIndex)
    "
    @applicantClick="handleApplicantClick"
    @possessionClick="setSelectedPossessionIndex"
  >
    <RejectionMessageCardDocs
      type="FAMILY_CERTIFICATE"
      :invalid-documents="invalidDocuments"
      :is-new-rejection="isNewRejection"
    />
    <CollapseCard
      :closed-identifier="'fam_cert_modal_closed'"
      :is-primary="true"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <p>
          Här laddar ni upp era familjebevis. Ett familjebevis är en viss typ av
          personbevis som inte bara visar folkbokföringsadress utan även
          eventuella barn och andra familjeförhållanden.
        </p>
        <p>
          Detta har på senare tid blivit allt vanligare att hyresvärdarna kräver
          och därför är detta obligatoriskt för alla. Oavsett om man är singel,
          sambo, ogift, gift eller lever under andra konstellationer så krävs
          ett familjebevis.
        </p>
        <br />
        <h3>Familjebeviset får ej vara äldre än 3 månader.</h3>
        <p>
          Tänk på att ladda upp familjebevis på samtliga som skall bo i den nya
          lägenheten, även personer under 18. Enklast är att varje vuxen
          beställer ett där även eventuella barns personuppgifter framgår. På så
          vis slipper man beställa flera olika.
        </p>
        <br />
        <h3>
          Familjebeviset skickas till din folkbokföringsadress via post och går
          ej att hämta online
        </h3>
        <br />
        <h3>Körkort, ID-kort samt pass gäller ej som personbevis.</h3>
        <br />
        <p>
          Beställning av <b>familjebevis</b> gör du enklast
          <a
            href="https://skatteverket.se/privat/folkbokforing/bestallpersonbevis/allapersonbevis.4.361dc8c15312eff6fdb20.html?mode=list&code=1209"
            target="_blank"
            >här</a
          >
          (länk till Skatteverket).
        </p>
        <p>
          Det går bra att ladda upp dokument som är inscannade så väl som
          fotokopior.
        </p>
        <br />
        <p><strong>Tänk på att:</strong></p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          Tips: För att fota dokument på bästa sätt rekommenderar vi att du
          använder dig av en app som scannar av dokument. Exempel på appar som
          fungerar bra till både iOS och Android är Adobe Scan och Genius Scan,
          båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href=" https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a :href="`mailto:${getSupportAddress}`" target="_blank">
            {{ getSupportAddress }}
          </a>
        </p>
      </template>
    </CollapseCard>
    <SwapDocumentUpload
      type="FAMILY_CERTIFICATE"
      :invalid-documents="invalidDocuments"
    ></SwapDocumentUpload>
  </SwapDefaultPage>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { getInvalidDocuments, getMembersListForTab } from '@/utils/common';

import SwapDocumentUpload from '@/components/SwapDocumentUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import RejectionMessageCardDocs from '@/components/RejectionMessageCardDocs.vue';

export default {
  name: 'FamilyCertificate',

  components: {
    SwapDocumentUpload,
    CollapseCard,
    SwapDefaultPage,
    RejectionMessageCardDocs
  },
  data() {
    return {
      getMembersListForTab
    };
  },
  computed: {
    ...mapGetters({
      applicant: 'application/applicant',
      getSupportAddress: 'app/getSupportAddress',
      useSplitDocumentUpload: 'application/useSplitDocumentUpload'
    }),
    ...mapState({
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      application: state => state.application.application,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    invalidDocuments() {
      if (!this.isNewRejection) {
        return {};
      }
      const documents =
        this.applicant?.documentGroups[this.selectedPossessionIndex]
          .FAMILY_CERTIFICATE.documents || [];
      return getInvalidDocuments(documents);
    },
    isNewRejection() {
      return !!this.applicant?.newRejections.documentGroups[
        this.selectedPossessionIndex
      ].FAMILY_CERTIFICATE;
    }
  },
  methods: {
    ...mapMutations({
      setSelectedApplicantIndex: 'application/setSelectedApplicantIndex',
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    handleApplicantClick(index) {
      this.setSelectedApplicantIndex(index);
      this.setSelectedPossessionIndex(0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>
<style></style>
