import wasCreatedBeforeSplitRelease from '@/utils/wasCreatedBeforeSplitRelease';
import { alphabet } from '@/utils/common';

export default {
  isSingleOwner(state) {
    if (!state.application) {
      return -1;
    }
    return (
      state.application.applicants.filter(applicant => applicant.owner)
        .length === 1
    );
  },
  isOwnerOfAllApplicants(state) {
    if (!state.application) {
      return null;
    }

    return state.application.applicants.every(applicant => applicant.owner);
  },
  isLb(state) {
    return /^\d+(-2)?$/.test(state.applicationId);
  },
  notStarted(state) {
    const notStarted = [];
    state.application?.applicants?.forEach((applicant, i) => {
      if (!applicant.started) {
        notStarted.push({
          name: applicant.members[0].name || `Bytespart ${alphabet[i]}`,
          owner: applicant.owner
        });
      }
    });

    return notStarted;
  },
  applicationIsSent(state) {
    if (!state.application) {
      return false;
    }
    return state.application.status === 'SENT';
  },
  applicationIsInactive(state) {
    if (!state.application) {
      return false;
    }
    return state.application.status === 'INACTIVE';
  },
  applicationIsArchived(state) {
    if (!state.application) {
      return false;
    }
    return state.application.status === 'ARCHIVED';
  },
  applicant(state) {
    if (!state.application) {
      return null;
    }

    return state.application.applicants[state.selectedApplicantIndex];
  },
  applicants(state) {
    if (!state.application) {
      return [];
    }
    return state.application.applicants.map(applicant => applicant.members[0]);
  },
  nextValidApplicantIndex(state) {
    if (!state.application) {
      return 0;
    }

    for (
      let i = state.selectedApplicantIndex + 1;
      i < state.application.applicants.length;
      i++
    ) {
      if (state.application.applicants[i].owner) {
        return i;
      }
    }

    return 0;
  },
  isApplicationOwner(state) {
    if (!state.application) {
      return false;
    }
    const initiator = state.application.applicants.find(
      a => a.id === state.application.initiatedBy
    );
    return initiator?.owner === true;
  },
  connectedLandlordApplicants(state) {
    if (!state.application) {
      return { connected: [], notConnected: [] };
    }
    const connected = [];
    const notConnected = [];

    state.application?.applicants.forEach(applicant => {
      applicant.apartments.forEach((apartment, apartmentIndex) => {
        if (apartment.landlordId > 0) {
          connected.push(applicant.members[apartmentIndex].name);
        } else {
          notConnected.push(applicant.members[apartmentIndex].name);
        }
      });
    });
    return {
      connected,
      notConnected
    };
  },
  summarizedFormStatus(state, getters) {
    const categoryStatuses = {};
    for (const applicantIndex in getters.applicantsFormStatuses) {
      const applicantStatuses = getters.applicantsFormStatuses[applicantIndex];
      for (const category in applicantStatuses) {
        if (!categoryStatuses[category]) {
          categoryStatuses[category] = [];
        }
        categoryStatuses[category].push(applicantStatuses[category]);
      }
    }

    for (const category in categoryStatuses) {
      const statuses = categoryStatuses[category];

      if (statuses.every(s => s === 'EMPTY')) {
        categoryStatuses[category] = 'EMPTY';
        continue;
      }

      if (statuses.every(s => s === 'APPROVED')) {
        categoryStatuses[category] = 'APPROVED';
        continue;
      }

      if (statuses.includes('COMMENT')) {
        categoryStatuses[category] = 'COMMENT';
        continue;
      }

      if (statuses.includes('INVALID')) {
        categoryStatuses[category] = 'INVALID';
        continue;
      }

      if (statuses.includes('INCOMPLETE') || statuses.includes('EMPTY')) {
        categoryStatuses[category] = 'INCOMPLETE';
        continue;
      }

      if (statuses.includes('COMPLETE')) {
        categoryStatuses[category] = 'COMPLETE';
      }
    }

    return categoryStatuses;
  },
  skandiaLandlordIds() {
    return [169290, 169296, 169297];
  },
  useSplitDocumentUpload: (state, getters) => category => {
    if (!state.application) {
      return false;
    }

    // Older application using system before release of document split
    if (wasCreatedBeforeSplitRelease(state.application)) {
      return false;
    }

    // Members sharing apartment only need to upload invoice/agreement once and
    // is therefore a "shared category"
    if (
      ['LEASE_AGREEMENT', 'RENTAL_INVOICE'].includes(category) &&
      getters.applicant.members.length > getters.applicant.apartments.length
    ) {
      return false;
    }

    return true;
  },
  applicantStatusForCategory:
    (state, getters) =>
    ({ index, category }) => {
      return Object.keys(getters.applicantsStatuses)?.length
        ? getters.applicantsStatuses[index][category]
        : 'EMPTY';
    },
  statusDescriptions: () => status => {
    const statusDescriptions = {
      APPROVED: 'Detta formulär har granskats av handläggare',
      COMMENT: 'Du har fått en kommentar i detta formulär',
      INCOMPLETE: 'Det saknas några uppgifter',
      REJECTED: '',
      UPDATED: '',
      COMPLETE: 'Allt är klart',
      INVALID: 'Det är något som är fel ifyllt',
      EMPTY: 'Ej påbörjad'
    };
    return statusDescriptions[status];
  }
};
